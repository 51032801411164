import { Outlet } from "react-router";
import { Routing } from "../../RouteInterfaces";
import SideBar from "../SideBar/SideBar";
import styles from "./Layout.module.css";
import { useMemo } from "react";
import TopBar from "../TopBar/TopBar";

const Layout = ({ routes }: Routing) => {

    return (
        <div className={styles.layoutContainer}>
            <SideBar routes={routes}/>
            <div className={styles.verticalContainer}>
            <TopBar/>
            <div className={styles.contentContainer}>
                <Outlet/>
            </div>
            </div>
        </div>
    );
}

export default Layout;