import { ReactElement, ReactNode } from 'react';
import { ChipItem } from './ChipItem';
import { PlusIcon } from '@components/icons';
import classNames from 'classnames';
import styles from './ChipArray.module.css';
import { IconButton } from '../button/IconButton';

export interface Chip {
  value: string;
  label: ReactNode;
  startIcon?: ReactNode;
}

export interface ChipArrayProps {
  chips: Chip[];
  className?: string;
  addIcon?: ReactNode;
  onRemove?: (value: string) => void;
  onAdd?: () => void;
  noDataMessage?: string;
}

export const ChipArray = ({
  chips,
  addIcon = (<PlusIcon/>),
  onRemove,
  onAdd,
  className,
  noDataMessage
}: ChipArrayProps): ReactElement => {

  const handleRemoveItem = (item: Chip) => {
    if (onRemove) onRemove(item.value);
  }

  return (
    <div className={classNames(className, styles.container)}>
      <div className={styles.items}>
        {chips.length === 0 && <div className={styles.noData}>{noDataMessage}</div>}
        {chips.length > 0 && chips.map(chip => (
          <ChipItem startIcon={chip.startIcon} onRemove={() => handleRemoveItem(chip)} key={chip.value}>
            {chip.label}
          </ChipItem>
        ))}
      </div>
      <IconButton className={styles.addBtn} onClick={onAdd}>{addIcon}</IconButton>
    </div>
  );
};
