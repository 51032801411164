import { ReactNode } from "react";
import { NonIndexRouteObject } from "react-router";

export interface Routing {
    routes: Array<BaseRoute>;
}

export interface BaseRoute extends NonIndexRouteObject {
    name: string;
    children?: Array<BaseRoute>;
    pathTo?: string;
    hidden?: boolean;
    icon?: ReactNode;
    permissionLevel: RoutePremissionLevelEnum;
    asModal?: boolean;
    parent?: string;
}

export enum RoutePremissionLevelEnum {
    None,
    Admin,
    SuperAdmin
}