import { useContext, useState, createContext, useEffect } from "react";
import { getUsersSelf, useLogin, useLogout } from "@api/__generated__/mainservice";
import { AXIOS_INSTANCE as api } from "@api/api";
import Cookies from "js-cookie";
import { Loader } from "@components/common";

interface AuthContextValue {
    userAgent: UserAgent | undefined,
    isAuthenticated: boolean;
    login: (email: string) => void;
    loginPending: boolean;
    logout: () => void;
}

export interface UserAgent {
    email: string,
    name: string,
    organization: string,
}

export const AuthContext = createContext<AuthContextValue | null>(null);

export const useAuthContext = () => {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error(
            "useAuthContext has to be used within <AuthProvider>"
        );
    }

    return context;
}

export const AuthContextProvider = ({ children }: any) => {
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [userAgent, setUserAgent] = useState<UserAgent>();

    const { mutate: loginUser, isPending: loginPending } = useLogin({ mutation: {
        onSuccess: response => {
            window.open(response.redirect_url, '_self');
        },
    } });
    const { mutate: logoutUser } = useLogout({ mutation: {
        onSuccess: response => { if(response.success) setIsAuthenticated(false); }
    } });

    const login = (email: string) => {
        loginUser({data: {
            email: email,
            auth_method: "oidc.code",
            token_response: { type: "cookie" }
        }});
    }

    const fetchUser = async () => {
        const response = await getUsersSelf();
        if (response && response.name && response.email && response.organization) {
            setUserAgent({ email: response.email, name: response.name, organization: response.organization });
        }
    }

    const trySilentLogin = async () => {
        setLoading(true);
        try {
            await fetchUser();
        }
        catch (err) {
            setIsAuthenticated(false);
        }
        finally {
            setLoading(false);
        }
    }

    const logout = () => {
        return logoutUser();
    }

    useEffect(() => {
        trySilentLogin();
    }, []);

    useEffect(() => {
        api.interceptors.response.use(
            undefined,
            (error) => {
              switch (error.response.status) {
                case 401:
                  Cookies.remove("Authorization");
                  setIsAuthenticated(false);
                  break;
                default:
                  return Promise.reject(error);
              }
            }
          );
    }, []);
      
    const value: AuthContextValue = {
        userAgent,
        isAuthenticated,
        loginPending,
        login,
        logout,
    };

    return (
        <AuthContext.Provider value={value}>
            {loading ? 
                (<Loader/>) :
                children
            }
        </AuthContext.Provider>
    )
}
