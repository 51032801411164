import { EmailIcon } from "@components/icons";
import styles from "./Login.module.css";
import logo from "@assets/logo-gradient.png";
import { Button, Input } from "@components/common";
import { useState, MouseEvent } from "react";
import { useAuthContext } from "@api/auth/AuthContext";

const Login = () => {

    const { login, loginPending } = useAuthContext();

    const [email, setEmail] = useState<string>("");
    const handleLogin = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        if (email.trim() !== "") login(email);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (email.trim() !== "") login(email);
        }
    }

    return (
        <div className={styles.loginContainer}>
            <h1>Welcome to CrypDefi</h1>
            <div className={styles.loginCard}>
                <img src={logo} alt="logo" />
                <h2>Login</h2>
                    <Input 
                        onKeyDown={handleKeyDown}
                        className={styles.inputField} 
                        type="text"
                        value={email} 
                        placeholder="Enter your email" 
                        onChange={(e) => setEmail(e.target.value)} 
                        startAdornment={EmailIcon()}
                        startAdornmentClassName={styles.inputIcon}
                    />
                    <Button disabled={loginPending} className={styles.loginBtn} onClick={handleLogin}>Next</Button>
            </div>
        </div>
    );
};

export default Login;