import { getUserIcon, transformGroups } from "@utils/transformers";
import { Wallet } from "../Wallets";
import { CopyText, List, ListColumnType, ListItem, Text } from "@components/common";
import styles from './WalletData.module.css';
import { getGetWalletPoliciesQueryKey, GroupSchema, useGetWalletPolicies, useGetWalletTokens, useUpdateWalletAccess, WalletTokenSchema } from "@api/__generated__/mainservice";
import { useMemo } from "react";
import { ChipArray } from "@components/common/chiparray/ChipArray";
import { AddUserIcon, CheckIcon, ErrorIcon } from "@components/icons";
import { useLocation, useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";

interface WalletDataProps {
    wallet?: Wallet;
    nestedType?: 'primary' | 'secondary';
}

interface Users {
    id: string;
    groups: GroupSchema[];
}

interface WalletToken extends WalletTokenSchema {
    id: string;
}

const WalletData = ({ wallet, nestedType = 'secondary' }: WalletDataProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    const { data } = useGetWalletPolicies(wallet?.id ?? "");
    const { data: tokensData } = useGetWalletTokens(wallet?.id ?? "");
    const { mutate: updateWalletAccess } = useUpdateWalletAccess({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetWalletPoliciesQueryKey(wallet?.id ?? "") });
        },
    } });

    const removeUserAccess = (userId: string) => {
        if (wallet?.id) updateWalletAccess({ walletId: wallet.id, data: { revoke: [userId] } });
    }
    
    const users: Users = useMemo(() => {
        if (!data) return { id: "someId", groups: [] };
        return { id: "someId", groups: data };
        }, [data]
    );

    const tokens: WalletToken[] = useMemo(() => {
        if (!tokensData) return [];
        return tokensData.map((token) => ({ id: token.name , ...token }));
        }, [tokensData]
    );

    const usersColumns: ListColumnType<Users>[] = 
    [
        {
            field: 'id',
            headerName: '',
            width: '100%',
            renderCell: (params) => (
                <ChipArray 
                    chips={transformGroups(params.data?.groups ?? [])
                        .map(x => 
                            ({
                                startIcon: getUserIcon(x.type),
                                label: x.name, 
                                value: x.id
                            })
                        )}
                    noDataMessage="No users"
                    addIcon={(<AddUserIcon/>)}
                    onAdd={() => navigate(`${wallet?.id}/adduser`, { state: { backgroundLocation: location } })}
                    onRemove={(value) => removeUserAccess(value)}
                />
            )
        },
    ];

    const tokenColumns: ListColumnType<WalletToken>[] = 
    [
        {
            field: 'name',
            headerName: 'Token',
            width: '70%',
        },
        {
            field: 'amount',
            headerName: 'Amount',
            width: '30%',
        },
    ];

    return (
        <div className={styles.walletData}>
            <div className={styles.grid}>
                {wallet && 
                    <div className={styles.col}>
                        <Text className={styles.labelTitle}>Wallet Details</Text>
                        <div className={classNames(styles.walletDetails, 
                            {[styles.primary]: nestedType === "primary", [styles.secondary]: nestedType === "secondary"})}
                        >
                            <div>
                                <div className={styles.label}>State</div>
                                {wallet.state === 'active' ? 
                                    <div className={styles.activeStatusCell}>active<CheckIcon/></div> : 
                                    <div className={styles.inactiveStatusCell}>inactive<ErrorIcon/></div>
                                }
                            </div>
                            <div>
                                <div className={styles.label}>Name</div><div className={styles.value}>{wallet.name}</div>
                            </div>
                            <div>
                                <div className={styles.label}>Wallet ID</div><div className={styles.value}>{wallet.wallet_id}</div>
                            </div>
                            <div>
                                <div className={styles.label}>Address</div>
                                <CopyText 
                                    className={styles.value}
                                    confirmationMessage="Wallet address copied to clipboard!"
                                >
                                    {wallet.address}
                                </CopyText>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.col}>
                    <Text className={styles.labelTitle}>Token Info</Text>
                    <List
                        className={styles.walletTokens}
                        variant='contained'
                        columns={tokenColumns}
                        data={tokens}
                        rowHeight={40}
                        size='small'
                        type={nestedType}
                    />
                </div>
            </div>
            <div>
                <Text className={styles.label}>Users</Text>
                <ListItem
                    className={styles.users}
                    height={80}
                    row={users}
                    columns={usersColumns}
                    variant='outlined'
                    type={nestedType}
                />
            </div>
        </div>
    );
};

export default WalletData;