import { PropsWithChildren, ReactNode } from 'react';

import styles from './Text.module.css';
import classNames from 'classnames';

interface Props {
  className?: string;
}

export const Text = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.text, className)}>
      {children}
    </div>
  );
};