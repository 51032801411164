/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * WalletApi
 * OpenAPI spec version: 1.0
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ChainSchema
} from '.././schemas'
import { customInstance } from '../../../api';
import type { ErrorType } from '../../../api';



/**
 * @summary Gets a list of supported chains
 */
export const getChains = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<ChainSchema[]>(
      {url: `/chains`, method: 'GET', signal
    },
      );
    }
  

export const getGetChainsQueryKey = () => {
    return [`/chains`] as const;
    }

    
export const getGetChainsQueryOptions = <TData = Awaited<ReturnType<typeof getChains>>, TError = ErrorType<void>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChains>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetChainsQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getChains>>> = ({ signal }) => getChains(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getChains>>, TError, TData> & { queryKey: QueryKey }
}

export type GetChainsQueryResult = NonNullable<Awaited<ReturnType<typeof getChains>>>
export type GetChainsQueryError = ErrorType<void>


export function useGetChains<TData = Awaited<ReturnType<typeof getChains>>, TError = ErrorType<void>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChains>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getChains>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetChains<TData = Awaited<ReturnType<typeof getChains>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChains>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getChains>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetChains<TData = Awaited<ReturnType<typeof getChains>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChains>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Gets a list of supported chains
 */

export function useGetChains<TData = Awaited<ReturnType<typeof getChains>>, TError = ErrorType<void>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getChains>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetChainsQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



