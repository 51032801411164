import { PropsWithChildren, ReactNode } from "react";
import { useLocation, useNavigate } from "react-router";
import { Button, buttonVariants } from "./Button";

interface NavigationButtonProps {
    className?: string;
    startIcon?: ReactNode;
    variant?: keyof typeof buttonVariants;
    keepBackground?: boolean;
    pathTo: string;
}

export const NavigationButton = ({ children, variant, className, startIcon, pathTo, keepBackground = false }: PropsWithChildren<NavigationButtonProps>) => {

    const navigate = useNavigate();
    const location = useLocation();

    const handleNavigation = () => {
        if (pathTo === "") {
            navigate("/");
        }
        else if (pathTo === "-1") {
            navigate(-1);
        }
        else {
            const navigationOptions = keepBackground ? { state: { backgroundLocation: location } } : undefined;
            navigate(pathTo, navigationOptions);
        }
    }
    return (
        <Button
            variant={variant}
            className={className}
            id={pathTo}
            onClick={handleNavigation}
            >
            {startIcon}
            {children}
        </Button>
    )
}