import { ReactNode } from 'react';
import { Text } from '../text';
import { DOMAttributes } from '@react-types/shared';
import classNames from 'classnames';

import styles from './InputMessage.module.css';

interface Props extends DOMAttributes {
    message?: string | ReactNode;
    variant?: 'default' | 'error';
}

export const InputMessage = ({ message, variant = 'default', ...rest }: Props) => {
    if (!message) {
        return null;
    }

    return (
        <Text
        {...rest}
        className={classNames(rest.className, {
            [styles.helperText]: variant === 'default',
            [styles.helperTextError]: variant === 'error',
        })}
        >
        {/* @ts-ignore */}
        {message}
        </Text>
    );
};
