import classnames from 'classnames';

import styles from './HamburgerMenu.module.css';
import { ReactNode, useState } from 'react';
import { SettingsIcon } from '@components/icons';
import { Button } from '../button/Button';

interface MenuOption {
  label: ReactNode;
  onClick?: () => void;
  className?: string;
}

interface Props {
  icon?: ReactNode;
  className?: string;
  options: MenuOption[];
  id?: string;
  animate?: boolean;
  align?: 'left' | 'bottom';
}

export const HamburgerMenu = ({ icon = (<SettingsIcon/>), className, options, id, animate = true, align = 'left' }: Props) => {

  const [open, setOpen] = useState<boolean>(false);

  const handleClickOption = (option: MenuOption) => {
    setOpen(false);
    if (option.onClick) option.onClick();
  }

  document.addEventListener('click', function(event){
    const menuContainer = document.getElementById(`HamburgerMenu-${id}`);
    if(open && event.target && menuContainer && !menuContainer.contains(event.target as HTMLElement)) {
        setOpen(false);
    }
  });

  return (
    <div id={`HamburgerMenu-${id}`} className={classnames(styles.container, className)}>
      <div className={classnames(styles.popover, {
          [styles.open]: open,
          [styles.left]: align === 'left',
          [styles.bottom]: align === 'bottom',
        })}>
        <Button className={classnames(styles.icon, styles.btn, {[styles.selected]: open && animate})} variant='text' onClick={() => setOpen(prev => !prev)}>
          {icon}
        </Button>
        {open && <div>
          {options.map((option) => (
            <Button className={classnames(styles.btn, option.className)} variant='text' onClick={() => handleClickOption(option)}>{option.label}</Button>
          ))}
        </div>}
      </div>
    </div>
  );
};
