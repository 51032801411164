import { Loader, Title } from '@components/common';
import styles from './Integrations.module.css';
import CustodianIntegration from './CustodianIntegration';
import { useGetCustodians } from '@api/__generated__/custodyrouter';

const Integrations = () => {

    const { data, isLoading } = useGetCustodians();

    return (
        <div className={styles.gridContainer}>
            <Title>Custodians</Title>
            {isLoading ? 
                <Loader/> :
                <div className={styles.grid}>
                    {data && data.map(custodian => (
                        <CustodianIntegration className={styles.gridItem} custodian={custodian}/>
                    ))}
                </div>
            }
        </div>
    );
};

export default Integrations;