import { useGetUsers, UserSchema } from "@api/__generated__/mainservice";
import { Loader, List, ListColumnType, ListActionType } from "@components/common";
import { BotIcon, TraderIcon } from "@components/icons";
import { useMemo, useState } from "react";
import styles from './Users.module.css';
import UserData from "./UserData/UserData";
import { HamburgerMenu } from "@components/common/hamburgermenu/HamburgerMenu";
import { useLocation, useNavigate } from "react-router";
import UserHeader from "./UserHeader";

export interface User extends UserSchema {
    id: string;
    settings?: any;
}

const Users = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [query, setQuery] = useState<string>("");

    const { data, isLoading } = useGetUsers();

    const rows: User[] = useMemo(() => {
        if (!data) return [];
        return data.map(row =>
            ({
                id: row.user_id,
                ...row
            }))
    }, [data]);

    const columns: ListColumnType<User>[] = useMemo(
        () => [
            {
                field: 'type',
                headerName: '',
                width: '5%',
                textAlign: 'center',
                renderCell: (params) => {
                    if(params.data?.type === "BOT") return (<div className={styles.icon}><BotIcon/></div>)
                    if(params.data?.type === "TRADER") return (<div className={styles.icon}><TraderIcon/></div>)
                    return <>{params.data?.type}</>
                },
            },
            {
                field: 'name',
                headerName: 'Name',
                width: '50%',
            },
            {
                field: 'email',
                headerName: 'Email',
                width: '45%',
            },
        ], []
    );

    const actions: ListActionType<User> = useMemo(
        () => 
            ({
                width: '40px',
                textAlign: 'center',
                renderActionCell: (params) => (
                    <HamburgerMenu
                        id={params.data?.id}
                        options={
                            [
                                {
                                    label: 'Edit',
                                    onClick: () => {navigate(`${params.data?.user_id}/edit`, { state: { backgroundLocation: location } })}
                                },
                                {
                                    label: 'Delete',
                                    onClick: () => {navigate(`${params.data?.user_id}/delete`, { state: { backgroundLocation: location } })},
                                    className: styles['error']
                                }
                            ]
                        }
                    />
                )
            })
        , [navigate]
    );

    const filteredRows = useMemo(() => {
            if (rows.length > 0 && query.trim() !== "") {
                return rows.filter(row => row.name.toLowerCase().includes(query.toLowerCase()));
            }
            return rows;
        }, [query, rows]
    );

    return (
        <>
            {isLoading ? (
                <Loader />
                ) : (
                <List
                    variant='outlined-free'
                    rowHeight={100}
                    className={styles.list}
                    header={(
                        <UserHeader onSearch={(search) => setQuery(search)}/>
                    )}
                    columns={columns}
                    data={filteredRows}
                    actions={actions}
                    content={{renderContent: (params) => (
                        <UserData user={params.data}/>
                    )}}
                />
            )}
        </>
    ); 
};

export default Users;