import { Item, Select } from '@components/common/select/Select';
import React, { ReactElement, ReactNode, useMemo, useState } from 'react';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';
import { InputMessage } from '../InputMessage';

export interface SelectOption {
  value: string;
  label: ReactNode;
}

export interface SelectFieldProps {
  options: SelectOption[];
  placeholder?: string;
  className?: string;
  onSelect?: (value: string) => void;
}

export const SelectField = ({
  name,
  options,
  onSelect,
  ...otherProps
}: UseControllerProps & SelectFieldProps): ReactElement => {
  const context = useFormContext();
  const [open, setOpen] = useState<boolean>(false);

  if (context === undefined) {
    throw new Error('SelectField must be used within a FormProvider from react-hook-form');
  }

  const handleSelect = (option: SelectOption) => {
    context.setValue(name, option.value);
    if (onSelect) onSelect(option.value);
    setOpen(false);
  }

  const selectedValue = context.watch(name) as string;

  const selectedOption = useMemo(() => {
    return options.find(option => option.value === selectedValue);
  }, [options, selectedValue]);

  const otherOptions = useMemo(() => {
    return options.filter(option => option.value !== selectedValue);
  }, [options, selectedValue]);

  return (
    <Controller
      control={context.control}
      name={name}
      render={({ field, fieldState }) => (
          <Select
            {...otherProps}
            {...field}
            btnClassName={otherProps.className}
            itemClassName={otherProps.className}
            selectedOption={selectedOption}
            open={open}
            toggleOpen={() => setOpen(prev =>!prev)}
          >
            <InputMessage variant="error" message={fieldState.error && fieldState.isTouched ? fieldState.error?.message as string : undefined} />
            {otherOptions.map(option => (
              <Item  key={option.value} itemClassName={otherProps.className} onSelect={() => handleSelect(option)}>
                {option.label}
              </Item>
            ))}
          </Select>
        )
      }
    />
  );
};
