import styles from "./ModalWrapper.module.css";
import { PropsWithChildren } from "react";

const ModalWrapper = ({ children }: PropsWithChildren) => {

    return (
        <div className={styles.overlay}>
            {children}
        </div>
    );
}

export default ModalWrapper;