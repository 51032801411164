import { ReactElement, useEffect, useState } from 'react';
import classnames from 'classnames';

import { ChevronDownIcon, ChevronUpIcon } from '../../icons';
import { Cell } from './Cell';
import styles from './ListItem.module.css';
import { ListColumnType, ListActionType, DefaultListObjectType, ListContentType } from './List.types';
import { ActionCell } from './ActionCell';
import classNames from 'classnames';
import { Button } from '../button/Button';

interface Props<ListObjectType extends DefaultListObjectType> {
  height: number;
  row: ListObjectType;
  columns: ListColumnType<ListObjectType>[];
  actionColumn?: ListActionType<ListObjectType>;
  content?: ListContentType<ListObjectType>;
  variant?: 'outlined' | 'outlined-free' | 'contained';
  type?: 'primary' | 'secondary';
  className?: string;
  defaultOpen?: boolean;
}

/**
 * ListItem component
 * @param params With all properties  from [[ListProps]]
 * @returns ReactElement
 */
export const ListItem = <ListObjectType extends DefaultListObjectType>({
  height,
  row,
  columns,
  content,
  actionColumn,
  variant = 'outlined',
  type = 'primary',
  className,
  defaultOpen
}: Props<ListObjectType>): ReactElement => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (defaultOpen) setOpen(true);
  }, [defaultOpen]);
  
  return (
    <div className={classNames(className, styles.listItemContainer, 
      {
        [styles.outlined]: variant === 'outlined' || variant === 'outlined-free', 
        [styles.contained]: variant === 'contained',
        [styles.primary]: (type === 'primary' && variant === 'outlined') || (variant === 'outlined-free' && type === 'secondary'),
        [styles.secondary]: (type === 'secondary' && variant === 'outlined') || (variant === 'outlined-free' && type === 'primary'),
      })}>
      <div className={styles.mainListItemContainer}>
        <div className={styles.dataContainer}>
          {columns?.map((column, index) => (
            <Cell height={height} row={row} column={column} key={`${row.id}_${index}`} />
          ))}
        </div>
        {actionColumn && (
          <ActionCell height={height} row={row} column={actionColumn} />
        )}
        {content && (
          <div onClick={() => setOpen(prev => !prev)} className={styles.iconContainer}>
            <Button className={styles.expandBtn}>{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</Button>
          </div>
        )}
      </div>
      {open && content && 
        <div className={styles.contentContainer}>
          {content.renderContent({ data: row })}
        </div>
      }
    </div>
  );
};
