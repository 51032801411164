import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';
import { Text } from '@components/common/text';
import styles from './Select.module.css';
import { ChevronDownIcon, ChevronUpIcon } from '@components/icons';
import { Button, buttonVariants, SelectOption } from '@components/common';

export interface SelectProps {
  containerClassName?: string;
  boxClassName?: string;
  btnClassName?: string;
  itemClassName?: string;
  placeholder?: string;
  selectedOption?: SelectOption;
  open: boolean;
  toggleOpen: () => void;
  variant?: 'expand' | 'shadow';
  btnVariant?: keyof typeof buttonVariants;
  disabled?: boolean;
}

export interface ItemProps {
  itemClassName?: string;
  onSelect: () => void;
}

export const Select = ({ variant = 'expand', ...props }: PropsWithChildren<SelectProps>): ReactElement => {
  
  return (
    <div className={classNames(styles.inputContainer, props.containerClassName)}>
        {props.selectedOption && !props.open && <Text className={classNames(styles.label, styles.outlined)}>{props.placeholder}</Text>}
        <div className={classNames(styles.listbox, props.boxClassName, 
            {
            [styles.popover]: props.open,
            [styles.expand]: props.open && variant === 'expand',
            [styles.shadow]: props.open && variant === 'shadow',
            })}
        >
            <Button 
                className={classNames(styles.btn, props.open ? props.itemClassName : props.btnClassName, {[styles.selected]: props.selectedOption})}
                onClick={props.toggleOpen}
                variant={props.btnVariant}
                disabled={props.disabled}
            >
                {props.selectedOption ? props.selectedOption.label : (<div className={styles.placeholderLabel}>{props.placeholder}</div>)}
                {props.open ? <div className={styles.shevron}><ChevronUpIcon/></div> : <div className={styles.shevron}><ChevronDownIcon/></div>}
            </Button>
            {props.open && 
                props.children
            }
        </div>
    </div>
  );
};

export const Item = ({ ...props }: PropsWithChildren<ItemProps>): ReactElement => {
  
  return (
    <Button 
      className={classNames(styles.btn, styles.item, props.itemClassName)}
      onClick={props.onSelect}
    >
        {props.children}
    </Button>
  );
};
