import classNames from 'classnames';
import styles from './HorizontalDivider.module.css';
import { PropsWithChildren } from 'react';

interface Props {
  className?: string
}

export const HorizontalDivider = ({ children, className }: PropsWithChildren<Props>) => {
  return (
    <div className={styles.container}>
      <div className={classNames(styles.line, className)}/>
      <div className={classNames(styles.text, className)}>{children}</div>
      <div className={classNames(styles.line, className)}/>
    </div>
  );
};