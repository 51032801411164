import { ReactElement, useCallback } from 'react';

import styles from './Cell.module.css';
import { ListActionType, DefaultListObjectType } from './List.types';

interface Props<ListObjectType extends DefaultListObjectType> {
  height: number;
  row: ListObjectType;
  column: ListActionType<ListObjectType>;
}

/**
 * Action Cell component
 * @param params With all properties  from [[ListProps]]
 * @returns ReactElement
 */
export const ActionCell = <ListObjectType extends DefaultListObjectType>({
  height,
  row,
  column,
}: Props<ListObjectType>): ReactElement => {
  const getCellValue = useCallback((column: ListActionType<ListObjectType>, row: ListObjectType) => {
    return column.renderActionCell({ data: row });
  }, []);

  return (
    <div
      style={{
        height: `${height}px`,
        ...(column.width ? { width: `${column.width}` } : {}),
        ...(column.textAlign ? { textAlign: column.textAlign, justifyContent: column.textAlign } : {}),
      }}
      className={styles.actionCell}
    >
      {getCellValue(column, row) as ReactElement}
    </div>
  );
};
