import { Title, WorkInProgress } from "@components/common";

const Policies = () => {
    return (
        <>
        <Title underlined>Policy</Title>
        <WorkInProgress/>
        </>
    );
};

export default Policies;