/**
 * Generated by orval v7.1.0 🍺
 * Do not edit manually.
 * Custody Router API
 * OpenAPI spec version: 1.0.0
 */
import {
  useMutation,
  useQuery
} from '@tanstack/react-query'
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ApiCredentialSchema,
  CustodianSchema,
  LinkedWalletSchema,
  TransactionRequestSchema,
  TransactionSchema
} from '.././schemas'
import { customInstance } from '../../../api';
import type { ErrorType } from '../../../api';



/**
 * @summary get custodians
 */
export const getCustodians = (
    
 signal?: AbortSignal
) => {
      
      
      return customInstance<CustodianSchema[]>(
      {url: `/custody`, method: 'GET', signal
    },
      );
    }
  

export const getGetCustodiansQueryKey = () => {
    return [`/custody`] as const;
    }

    
export const getGetCustodiansQueryOptions = <TData = Awaited<ReturnType<typeof getCustodians>>, TError = ErrorType<unknown>>( options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodians>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustodiansQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustodians>>> = ({ signal }) => getCustodians(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustodians>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustodiansQueryResult = NonNullable<Awaited<ReturnType<typeof getCustodians>>>
export type GetCustodiansQueryError = ErrorType<unknown>


export function useGetCustodians<TData = Awaited<ReturnType<typeof getCustodians>>, TError = ErrorType<unknown>>(
  options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodians>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustodians>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustodians<TData = Awaited<ReturnType<typeof getCustodians>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodians>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustodians>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustodians<TData = Awaited<ReturnType<typeof getCustodians>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodians>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary get custodians
 */

export function useGetCustodians<TData = Awaited<ReturnType<typeof getCustodians>>, TError = ErrorType<unknown>>(
  options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodians>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustodiansQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary create custodians
 */
export const createCustodians = (
    
 ) => {
      
      
      return customInstance<void>(
      {url: `/custody`, method: 'POST'
    },
      );
    }
  


export const getCreateCustodiansMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCustodians>>, TError,void, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof createCustodians>>, TError,void, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof createCustodians>>, void> = () => {
          

          return  createCustodians()
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type CreateCustodiansMutationResult = NonNullable<Awaited<ReturnType<typeof createCustodians>>>
    
    export type CreateCustodiansMutationError = ErrorType<unknown>

    /**
 * @summary create custodians
 */
export const useCreateCustodians = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof createCustodians>>, TError,void, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof createCustodians>>,
        TError,
        void,
        TContext
      > => {

      const mutationOptions = getCreateCustodiansMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary encrypts and saves the api credential
 */
export const addCustodianApiCredential = (
    custodianId: string,
    apiCredentialSchema: ApiCredentialSchema,
 ) => {
      
      
      return customInstance<void>(
      {url: `/custody/${custodianId}`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: apiCredentialSchema
    },
      );
    }
  


export const getAddCustodianApiCredentialMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCustodianApiCredential>>, TError,{custodianId: string;data: ApiCredentialSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof addCustodianApiCredential>>, TError,{custodianId: string;data: ApiCredentialSchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof addCustodianApiCredential>>, {custodianId: string;data: ApiCredentialSchema}> = (props) => {
          const {custodianId,data} = props ?? {};

          return  addCustodianApiCredential(custodianId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type AddCustodianApiCredentialMutationResult = NonNullable<Awaited<ReturnType<typeof addCustodianApiCredential>>>
    export type AddCustodianApiCredentialMutationBody = ApiCredentialSchema
    export type AddCustodianApiCredentialMutationError = ErrorType<unknown>

    /**
 * @summary encrypts and saves the api credential
 */
export const useAddCustodianApiCredential = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof addCustodianApiCredential>>, TError,{custodianId: string;data: ApiCredentialSchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof addCustodianApiCredential>>,
        TError,
        {custodianId: string;data: ApiCredentialSchema},
        TContext
      > => {

      const mutationOptions = getAddCustodianApiCredentialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary encrypts and saves the new api credential
 */
export const resetCustodianApiCredential = (
    custodianId: string,
    apiCredentialSchema: ApiCredentialSchema,
 ) => {
      
      
      return customInstance<void>(
      {url: `/custody/${custodianId}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: apiCredentialSchema
    },
      );
    }
  


export const getResetCustodianApiCredentialMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetCustodianApiCredential>>, TError,{custodianId: string;data: ApiCredentialSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof resetCustodianApiCredential>>, TError,{custodianId: string;data: ApiCredentialSchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof resetCustodianApiCredential>>, {custodianId: string;data: ApiCredentialSchema}> = (props) => {
          const {custodianId,data} = props ?? {};

          return  resetCustodianApiCredential(custodianId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ResetCustodianApiCredentialMutationResult = NonNullable<Awaited<ReturnType<typeof resetCustodianApiCredential>>>
    export type ResetCustodianApiCredentialMutationBody = ApiCredentialSchema
    export type ResetCustodianApiCredentialMutationError = ErrorType<unknown>

    /**
 * @summary encrypts and saves the new api credential
 */
export const useResetCustodianApiCredential = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof resetCustodianApiCredential>>, TError,{custodianId: string;data: ApiCredentialSchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof resetCustodianApiCredential>>,
        TError,
        {custodianId: string;data: ApiCredentialSchema},
        TContext
      > => {

      const mutationOptions = getResetCustodianApiCredentialMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all wallets
 */
export const getCustodianWallet = (
    walletId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<LinkedWalletSchema>(
      {url: `/custody/wallets/${walletId}`, method: 'GET', signal
    },
      );
    }
  

export const getGetCustodianWalletQueryKey = (walletId: string,) => {
    return [`/custody/wallets/${walletId}`] as const;
    }

    
export const getGetCustodianWalletQueryOptions = <TData = Awaited<ReturnType<typeof getCustodianWallet>>, TError = ErrorType<unknown>>(walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallet>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustodianWalletQueryKey(walletId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustodianWallet>>> = ({ signal }) => getCustodianWallet(walletId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(walletId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallet>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustodianWalletQueryResult = NonNullable<Awaited<ReturnType<typeof getCustodianWallet>>>
export type GetCustodianWalletQueryError = ErrorType<unknown>


export function useGetCustodianWallet<TData = Awaited<ReturnType<typeof getCustodianWallet>>, TError = ErrorType<unknown>>(
 walletId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallet>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustodianWallet>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustodianWallet<TData = Awaited<ReturnType<typeof getCustodianWallet>>, TError = ErrorType<unknown>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallet>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustodianWallet>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustodianWallet<TData = Awaited<ReturnType<typeof getCustodianWallet>>, TError = ErrorType<unknown>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get all wallets
 */

export function useGetCustodianWallet<TData = Awaited<ReturnType<typeof getCustodianWallet>>, TError = ErrorType<unknown>>(
 walletId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallet>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustodianWalletQueryOptions(walletId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary request a transaction
 */
export const requestTransactionToWallet = (
    walletId: string,
    transactionRequestSchema: TransactionRequestSchema,
 ) => {
      
      
      return customInstance<TransactionSchema>(
      {url: `/custody/wallets/${walletId}/transfer`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: transactionRequestSchema
    },
      );
    }
  


export const getRequestTransactionToWalletMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestTransactionToWallet>>, TError,{walletId: string;data: TransactionRequestSchema}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof requestTransactionToWallet>>, TError,{walletId: string;data: TransactionRequestSchema}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof requestTransactionToWallet>>, {walletId: string;data: TransactionRequestSchema}> = (props) => {
          const {walletId,data} = props ?? {};

          return  requestTransactionToWallet(walletId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type RequestTransactionToWalletMutationResult = NonNullable<Awaited<ReturnType<typeof requestTransactionToWallet>>>
    export type RequestTransactionToWalletMutationBody = TransactionRequestSchema
    export type RequestTransactionToWalletMutationError = ErrorType<unknown>

    /**
 * @summary request a transaction
 */
export const useRequestTransactionToWallet = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof requestTransactionToWallet>>, TError,{walletId: string;data: TransactionRequestSchema}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof requestTransactionToWallet>>,
        TError,
        {walletId: string;data: TransactionRequestSchema},
        TContext
      > => {

      const mutationOptions = getRequestTransactionToWalletMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Get all wallets
 */
export const getCustodianWallets = (
    custodianId: string,
 signal?: AbortSignal
) => {
      
      
      return customInstance<LinkedWalletSchema[]>(
      {url: `/custody/${custodianId}/wallets`, method: 'GET', signal
    },
      );
    }
  

export const getGetCustodianWalletsQueryKey = (custodianId: string,) => {
    return [`/custody/${custodianId}/wallets`] as const;
    }

    
export const getGetCustodianWalletsQueryOptions = <TData = Awaited<ReturnType<typeof getCustodianWallets>>, TError = ErrorType<unknown>>(custodianId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallets>>, TError, TData>>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetCustodianWalletsQueryKey(custodianId);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getCustodianWallets>>> = ({ signal }) => getCustodianWallets(custodianId, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(custodianId), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallets>>, TError, TData> & { queryKey: QueryKey }
}

export type GetCustodianWalletsQueryResult = NonNullable<Awaited<ReturnType<typeof getCustodianWallets>>>
export type GetCustodianWalletsQueryError = ErrorType<unknown>


export function useGetCustodianWallets<TData = Awaited<ReturnType<typeof getCustodianWallets>>, TError = ErrorType<unknown>>(
 custodianId: string, options: { query:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallets>>, TError, TData>> & Pick<
        DefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustodianWallets>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustodianWallets<TData = Awaited<ReturnType<typeof getCustodianWallets>>, TError = ErrorType<unknown>>(
 custodianId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallets>>, TError, TData>> & Pick<
        UndefinedInitialDataOptions<
          Awaited<ReturnType<typeof getCustodianWallets>>,
          TError,
          TData
        > , 'initialData'
      >, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
export function useGetCustodianWallets<TData = Awaited<ReturnType<typeof getCustodianWallets>>, TError = ErrorType<unknown>>(
 custodianId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallets>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey }
/**
 * @summary Get all wallets
 */

export function useGetCustodianWallets<TData = Awaited<ReturnType<typeof getCustodianWallets>>, TError = ErrorType<unknown>>(
 custodianId: string, options?: { query?:Partial<UseQueryOptions<Awaited<ReturnType<typeof getCustodianWallets>>, TError, TData>>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetCustodianWalletsQueryOptions(custodianId,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary imports the custodian wallets
 */
export const importCustodianWallets = (
    custodianId: string,
    importCustodianWalletsBody: string[],
 ) => {
      
      
      return customInstance<void>(
      {url: `/custody/${custodianId}/wallets`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: importCustodianWalletsBody
    },
      );
    }
  


export const getImportCustodianWalletsMutationOptions = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importCustodianWallets>>, TError,{custodianId: string;data: string[]}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof importCustodianWallets>>, TError,{custodianId: string;data: string[]}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof importCustodianWallets>>, {custodianId: string;data: string[]}> = (props) => {
          const {custodianId,data} = props ?? {};

          return  importCustodianWallets(custodianId,data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type ImportCustodianWalletsMutationResult = NonNullable<Awaited<ReturnType<typeof importCustodianWallets>>>
    export type ImportCustodianWalletsMutationBody = string[]
    export type ImportCustodianWalletsMutationError = ErrorType<unknown>

    /**
 * @summary imports the custodian wallets
 */
export const useImportCustodianWallets = <TError = ErrorType<unknown>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof importCustodianWallets>>, TError,{custodianId: string;data: string[]}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof importCustodianWallets>>,
        TError,
        {custodianId: string;data: string[]},
        TContext
      > => {

      const mutationOptions = getImportCustodianWalletsMutationOptions(options);

      return useMutation(mutationOptions);
    }
    