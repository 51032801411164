import { CopyText, EmptyState, List, ListColumnType, Loader, Text } from "@components/common";
import styles from './WalletData.module.css';
import { useMemo } from "react";
import classNames from "classnames";
import { AssetSchema, useGetCustodianWallet } from "@api/__generated__/custodyrouter";

interface WalletDataProps {
    walletId?: string;
    nestedType?: 'primary' | 'secondary';
}

interface WalletToken extends AssetSchema {
    id: string;
}

const LinkedWalletData = ({ walletId, nestedType = 'secondary' }: WalletDataProps) => {

    const { data: wallet, isLoading } = useGetCustodianWallet(walletId ?? "");

    const assets : WalletToken[] = useMemo(() => {
        if (!wallet || !wallet.assets) return [];
        return wallet.assets.map(w => ({...w, id: w.asset_id}));
    }, [wallet]);

    const tokenColumns: ListColumnType<WalletToken>[] = 
    [
        {
            field: 'ticker',
            headerName: 'Token',
            width: '30%',
        },
        {
            field: 'balance',
            headerName: 'Balance',
            width: '30%',
        },
        {
            field: 'value',
            headerName: 'Amount',
            width: '40%',
        },
    ];

    if (isLoading) {
        return (<Loader/>)
    }

    if (!wallet) {
        return (<EmptyState title="Linked wallet not found"/>)
    }

    return (
        <div className={styles.walletData}>
            <div className={styles.grid}>
                {wallet && 
                    <div className={styles.col}>
                        <Text className={styles.labelTitle}>Wallet Details</Text>
                        <div className={classNames(styles.walletDetails, 
                            {[styles.primary]: nestedType === "primary", [styles.secondary]: nestedType === "secondary"})}
                        >
                            <div>
                                <div className={styles.label}>Name</div><div className={styles.value}>{wallet.name}</div>
                            </div>
                            <div>
                                <div className={styles.label}>Wallet ID</div><div className={styles.value}>{wallet.internal_wallet_id}</div>
                            </div>
                            <div>
                                <div className={styles.label}>External Wallet ID</div><div className={styles.value}>{wallet.external_wallet_id}</div>
                            </div>
                            <div>
                                <div className={styles.label}>Address</div>
                                <CopyText 
                                    className={styles.value}
                                    confirmationMessage="Wallet address copied to clipboard!"
                                >
                                    {wallet.address}
                                </CopyText>
                            </div>
                        </div>
                    </div>
                }
                <div className={styles.col}>
                    <Text className={styles.labelTitle}>Token Info</Text>
                    <List
                        className={styles.walletTokens}
                        variant='contained'
                        columns={tokenColumns}
                        data={assets}
                        rowHeight={40}
                        size='small'
                        type={nestedType}
                    />
                </div>
            </div>
        </div>
    );
};

export default LinkedWalletData;