import classNames from "classnames";
import styles from "./TopBarMenu.module.css";
import { useAuthContext } from "@api/auth/AuthContext";
import { Button, Text } from "@components/common";
import { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon, EmailIcon, LogoutIcon, OfficeIcon, UserIcon } from "@components/icons";

const TopBarMenu = () => {

    const { userAgent, logout } = useAuthContext();

    const [open, setOpen] = useState<boolean>(false);

    const handleClickLogout = () => {
        setOpen(false);
        logout();
    }

    document.addEventListener('click', function(event){
        const menuContainer = document.getElementById(`TopBarMenu-${userAgent?.email}`);
        if(open && event.target && menuContainer && !menuContainer.contains(event.target as HTMLElement)) {
            setOpen(false);
        }
    });

    if (!userAgent) {
        return (<></>);
    }

    return (
        <div id={`TopBarMenu-${userAgent?.email}`}>
            <Button className={classNames(styles.btn, styles.nameBtn)} variant="text" onClick={() => setOpen(prev => !prev)}>
                <div className={styles.nameInfo}>
                    <div className={styles.icon}><UserIcon/></div>
                    {userAgent.name}
                </div>
                <ChevronDownIcon/>
            </Button>
            <div className={classNames(styles.popover, {[styles.open]: open})}>
                <Button className={classNames(styles.btn, styles.nameBtn)} variant="text" onClick={() => setOpen(prev => !prev)}>
                    <div className={styles.nameInfo}>
                        <div className={styles.icon}><UserIcon/></div>
                        {userAgent.name}
                    </div>
                    <ChevronUpIcon/>
                </Button>
                <Text className={styles.info}>
                    <div className={styles.icon}><EmailIcon/></div>
                    {userAgent.email}
                </Text>
                <Text className={styles.info}>
                    <div className={styles.icon}><OfficeIcon/></div>
                    {userAgent.organization}
                </Text>
                <Button className={classNames(styles.btn, styles.error)} variant='text' onClick={() => handleClickLogout()}>
                    <div className={classNames(styles.icon, styles.error)}><LogoutIcon/></div>
                    Logout
                </Button>
            </div>
        </div>          
        
    );
}

export default TopBarMenu;