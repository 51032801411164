import { Loader, Title, WorkInProgress } from "@components/common";

const Analytics = () => {
    return (
        <>
        <Title underlined>Analytics</Title>
        <WorkInProgress/>
        </>
    );
};

export default Analytics;