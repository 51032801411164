import classNames from 'classnames';

import styles from './MultilineInput.module.css';
import { InputVariants } from './Input';
import React from 'react';

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement>  {
  error?: boolean;
  variant?: keyof typeof InputVariants;
  legend?: string;
}

export const MultilineInput = React.forwardRef<HTMLTextAreaElement, Props>(
  ({error, disabled, className, variant = 'naked', legend, ...rest }, ref) => {
    return (
      <div className={styles.inputContainer}>
        <fieldset
          className={classNames(styles.input, styles[variant], className, {
            [styles.errorInputField]: error,
            [styles.legendPadding]: !legend
          })}
        >
          {legend && <legend className={styles.legend}>{legend}</legend>}
          <textarea
            rows={4}
            className={classNames(styles.field)}
            ref={ref}
            {...rest}
          />
        </fieldset>
      </div>
    );
  }
);
