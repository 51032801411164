import { PropsWithChildren } from 'react';

import styles from './InputAdornment.module.css';

export const InputAdornment = ({ children, onClick }: PropsWithChildren<{ onClick?: () => void }>) => {
  return (
    <span onClick={onClick} className={styles.icon}>
      {children}
    </span>
  );
};
