import { Loader, List, ListColumnType, CopyText, Toggle, Title, Text, IconButton, Button } from "@components/common";
import { useMemo, useState } from "react";
import { getChainIcon } from "@utils/transformers";
import styles from "./ImportWallet.module.css";
import { useNavigate, useParams } from "react-router";
import { LinkedWalletSchema, useGetCustodianWallets, useImportCustodianWallets } from "@api/__generated__/custodyrouter";
import { PlusIcon } from "@components/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbar } from "notistack";
import { getGetWalletsQueryKey } from "@api/__generated__/mainservice";

export interface Wallet extends LinkedWalletSchema {
    id: string;
}

const ImportWallet = () => {

    const { custodianId } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const [walletsToImport, setWalletsToImport] = useState<string[]>([]);

    const { data: walletData, isLoading } = useGetCustodianWallets(custodianId ?? "");
    const wallets: Wallet[] = useMemo(() => {
        const result = walletData?.map(x => ({ ...x, id: x.external_wallet_id ?? ""})) ?? [];
        return result;
    }, [walletData]);

    const { mutate: importWallets, isPending } = useImportCustodianWallets({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetWalletsQueryKey() });
            navigate("/wallets");
        },
        onError: () => {
            enqueueSnackbar('Failed to import wallet.', {variant: 'error'});
        }
    } });

    const columns: ListColumnType<Wallet>[] = useMemo(
        () => [
            {
                field: 'id',
                headerName: '',
                width: '64px',
                textAlign: 'left',
                renderCell: (params) => (
                    <Toggle value={(params.data && walletsToImport.includes(params.data.id)) ?? false} onChange={(value) => {
                        if (params.data !== undefined) {
                            if (value) {
                                setWalletsToImport(prev => [...prev, params.data!.id]);
                            } else {
                                setWalletsToImport(prev => prev.filter(x => x !== params.data!.id));
                            }
                        }
                    }}/>
                ),
            },
            {
                field: 'chain',
                headerName: '',
                width: '20%',
                renderCell: (params) => (
                    <div className={styles.chain}>
                        <div className={styles.iconSmall}>{getChainIcon(params.data?.chain.name ?? "")}</div>{params.data?.chain.name}
                    </div>
                ),
            },
            {
                field: 'name',
                headerName: 'Name',
                width: '40%',
            },
            {
                field: 'address',
                headerName: 'Address',
                width: '40%',
                renderCell: (params) => 
                    <CopyText 
                        copyValue={params.data?.address}
                        confirmationMessage="Wallet address copied to clipboard!"
                    >
                        {params.data?.address.slice(0, 10) + " ...... " + params.data?.address.slice(-10)}
                    </CopyText>
            },
        ], [walletsToImport]
    );

    const handleSubmit = () => {
        if (custodianId && walletsToImport.length > 0) {
            importWallets({custodianId, data: walletsToImport})
        }
    }

    return (
        <>
            {isLoading ? (
                <Loader />
                ) : (
                <List
                    header={(
                        <div className={styles.header}>
                            <Title className={styles.title}>Fordefi Wallets</Title>
                            <div className={styles.actionContainer}>
                                <Toggle label='select all' value={walletsToImport.length === wallets.length} onChange={(value) => {
                                    if (value) {
                                        setWalletsToImport(wallets.map(x => x.id));
                                    } else {
                                        setWalletsToImport([]);
                                    }
                                }}/>
                                <Button
                                    disabled={walletsToImport.length === 0 || isPending}
                                    className={styles.importBtn}
                                    onClick={handleSubmit}
                                >
                                    <PlusIcon/><Text>Import</Text>
                                </Button>
                            </div>
                        </div>
                    )}
                    className={styles.list}
                    variant='outlined-free'
                    rowHeight={64}
                    columns={columns}
                    data={wallets}
                />
            )}
        </>
    ); 
};

export default ImportWallet;