import { getGetGroupsQueryKey, useDeleteGroup } from "@api/__generated__/mainservice";
import { Title, Button, Modal, Text } from "@components/common";
import styles from './DeleteGroup.module.css';
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from "notistack";

const DeleteGroup = () => {
    const { groupId } = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const { enqueueSnackbar } = useSnackbar();

    const { mutate: deleteGroup, isPending } = useDeleteGroup({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetGroupsQueryKey() });
            navigate(-1);
        },
        onError: () => {
            enqueueSnackbar('Failed to delete group.', {variant: 'error'});
        }
    } });

    const handleDeleteGroup = () => {
        if (groupId) deleteGroup({ groupId });
    };

    return (
        <Modal className={styles.card}>
            <Title underlined>Delete Group</Title>
            <div className={styles.content}>
                <Text  className={styles.warning}>Are you sure you want to delete this group with id: "{groupId}"?</Text>
                <Button
                    disabled={isPending}
                    className={styles.submitBtn}
                    type="button"
                    variant="contained"
                    onClick={() => handleDeleteGroup()}
                    >
                    Delete
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteGroup;