import classNames from "classnames";
import styles from "./TopBar.module.css";
import TopBarMenu from "./TopBarMenu";
import { HelpIcon } from "@components/icons";
import { Button } from "@components/common";
import BreadCrumbs from "./BreadCrumbs";

const TopBar = () => {

    const contactSupport = () => {
        window.location.href = `mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`;
    }

    return (
        <div className={styles.topbarContainer}>
            <div className={styles.leftContainer}>
                <BreadCrumbs/>
            </div>
            <div className={styles.rightContainer}>
                <Button className={styles.btn} variant="text" onClick={() => contactSupport()}>
                    <HelpIcon/>
                    Support
                </Button>
                <TopBarMenu/>
            </div>
        </div>
    );
}

export default TopBar;