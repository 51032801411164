import classNames from "classnames";
import styles from "./BreadCrumbs.module.css";
import { useAuthContext } from "@api/auth/AuthContext";
import { NavigationButton } from "@components/common";
import { useLocation } from "react-router";

const BreadCrumbs = () => {

    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);

    return (
        <div className={styles.breadcrumbs}>
            <NavigationButton className={styles.btn} variant="text" pathTo="/">home</NavigationButton>
            {pathnames.map((pathname, index) => (
                <div className={styles.breadcrumbs} key={pathname}>
                /    
                <NavigationButton className={styles.btn} variant="text" pathTo={pathnames.slice(0, index + 1).join('/')}>
                    <div className={styles.text}>{pathname}</div>
                </NavigationButton>
                </div>  
            ))}
        </div>          
        
    );
}

export default BreadCrumbs;