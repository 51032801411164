import { useMemo } from "react";
import { Navigate, Outlet, useLocation, useRoutes, Routes as ReactRoutes, Route } from "react-router";
import Layout from "../Layout/Layout";
import { authenticatedRoutes, unauthenticatedRoutes } from "../../routes";
import { useAuthContext } from "@api/auth/AuthContext";
import ModalWrapper from "../Layout/ModalWrapper";

const Routes = () => {

    const { isAuthenticated } = useAuthContext();

    const location = useLocation();
    const state = location.state as { backgroundLocation?: Location };
    const backgroundLocation = state?.backgroundLocation || location;

    const modalRoutes = authenticatedRoutes.filter(route => route.asModal);

    const routes = useMemo(() => {
        if (isAuthenticated) {
            return [
                {
                    caseSensitive: true,
                    path: '',
                    element: <Navigate to={'wallets'} replace/>,
                },
                {
                    caseSensitive: true,
                    path: '/*',
                    element: <Navigate to={'wallets'} replace/>,
                },
                {
                    caseSensitive: true,
                    path: '/',
                    element: <Layout routes={authenticatedRoutes}/>,
                    children: authenticatedRoutes
                },
            ];
        }

        return [
            {
                caseSensitive: true,
                path: '',
                element: <Navigate to={'login'} replace/>,
            },
            {
                caseSensitive: true,
                path: '/*',
                element: <Navigate to={'login'} replace/>,
            },
            {
                caseSensitive: true,
                path: '/',
                element: <Outlet />,
                children: unauthenticatedRoutes,
            },
        ];
    }, [isAuthenticated]);

    const elements = useRoutes(routes, backgroundLocation);

    return (
        <>
        {elements}
        {isAuthenticated && state?.backgroundLocation && (
            <ReactRoutes>
                {modalRoutes.map(route => (
                    <Route key={route.path} path={route.path} element={<ModalWrapper>{route.element}</ModalWrapper>} />
                ))}
            </ReactRoutes>
        )}
        </>
    );
}

export default Routes;