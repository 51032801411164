import { PropsWithChildren } from 'react';

import styles from './Title.module.css';
import classNames from 'classnames';

interface Props {
  className?: string;
  underlined?: boolean;
  size?: keyof typeof sizeVariants;
}

const sizeVariants = {
  small: 'small',
  medium: 'medium',
  large: 'large',
  xlarge: 'xlarge'
};

export const Title = ({ size = 'large', children, className, underlined = false }: PropsWithChildren<Props>) => {
  return (
    <div className={classNames(styles.titleContainer, className)}>
      <div className={classNames(styles.title, size, 
        { 
          [styles.underlined]: underlined, 
          [styles.small]: size === sizeVariants.small,
          [styles.medium]: size === sizeVariants.medium,
          [styles.large]: size === sizeVariants.large,
          [styles.xlarge]: size === sizeVariants.xlarge,
        })}>
        {children}
      </div>
    </div>
  );
};