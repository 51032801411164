import fordefi from "@assets/fordefi.svg";
import crypdefi from "@assets/logo.svg";

const custodians = [
    {
        id: "cd-001",
        name: "Fordefi",
        logoSrc: fordefi,
        description: "Connect your Fordefi account to harmonize your assets and enable easy asset transfers.",
        connectMsg: "We only need the access key for an API user with minimal access in order to connect your account. We save an encypted version of this key to make requests on your behalf.",
        reconnectMsg: "This will override the current api key and this key cannot be recovered after this operation."
    }
];

export const getCustodianLogoSrc = (custodianId?: string) => {
    return custodians.find(x => x.id === custodianId)?.logoSrc ?? crypdefi;
}

export const getCustodianDescription = (custodianId?: string) => {
    return custodians.find(x => x.id === custodianId)?.description ?? "";
}

export const getCustodianName = (custodianId?: string) => {
    return custodians.find(x => x.id === custodianId)?.name ?? "";
}

export const getCustodianConnectMsg = (custodianId?: string) => {
    return custodians.find(x => x.id === custodianId)?.connectMsg ?? "";
}

export const getCustodianReconnectMsg = (custodianId?: string) => {
    return custodians.find(x => x.id === custodianId)?.reconnectMsg ?? "";
}

export const isWalletNative = (walletId?: string) => {
    if (!walletId) return false;
    return walletId.startsWith("wa-");
}