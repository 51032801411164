import { useGetWallets } from "@api/__generated__/mainservice";
import { Title, Button, Modal, SelectField, RadioGroup, NumberField } from "@components/common";
import styles from './AssetTransfer.module.css';
import { TextField, Text } from "@components/common";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router";
import { useQueryClient } from '@tanstack/react-query';
import { useMemo, useState } from "react";
import { getChainIcon } from "@utils/transformers";
import { useSnackbar } from "notistack";
import { useGetCustodianWallet, useRequestTransactionToWallet } from "@api/__generated__/custodyrouter";
import { watch } from "fs";

interface TransferForm {
    toWallet: string
    amount?: number
    asset: string
    priority: string
}

const mockData = {
    "address": "0x3e8dca8a49b7f",
    "assets": [
    {
        "asset_id": "asset-742",
        "balance": { "amount": 320.12, "currency": "USD" },
        "network": "ERC-20",
        "price": 4532.5,
        "ticker": "ETH",
        "value": 8765
    },
    {
        "asset_id": "asset-341",
        "balance": { "amount": 500.45, "currency": "USD" },
        "network": "ERC-20",
        "price": 2000.75,
        "ticker": "DOGE",
        "value": 6500
    }
    ],
    "balance": { "amount": 1500.3, "currency": "USD" },
    "chain": { "id": "chain-450", "name": "Ethereum Mainnet" },
    "external_wallet_id": "ext-wallet-123",
    "internal_wallet_id": "int-wallet-456",
    "name": "My Ethereum Wallet"
};

const AssetTransfer = () => {
    const { walletId } = useParams();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [network, setNetwork] = useState<string>();

    const { data: wallet } = useGetCustodianWallet(walletId ?? "");

    const { data: wallets } = useGetWallets(
        {
            nativeOnly: true,
            chainId: "ch-60",
            state: "active"
        },
        { query: { enabled: Boolean(wallet) }}
    );

    const { mutate: requestTransfer } = useRequestTransactionToWallet({ mutation: {
        onSuccess: async () => {
            navigate(-1);
        },
        onError: () => {
            enqueueSnackbar('Failed to request transfer.', {variant: 'error'});
        }
    } });

    const methods = useForm<TransferForm>({
        resolver: yupResolver(
            Yup.object({
                amount: Yup.number().integer("Amount must be an integer"),
                toWallet: Yup.string().trim().required("Destination wallet is required"),
                priority: Yup.string().trim().required("Select priority"),
                asset: Yup.string().required("Select asset"),
            })
        ),
        defaultValues: { 
            toWallet: '',
            priority: 'Medium',
            asset: ''
        },
        mode: 'onChange',
        
    });

    const onSubmit = (values: TransferForm) => {
        if (walletId && network) {
            requestTransfer(
                {
                    walletId: walletId, 
                    data: 
                    { 
                        to_wallet_id: values.toWallet, 
                        priority: values.priority as 'Low' | 'Medium' | 'High', 
                        network: network, 
                        asset: values.asset, 
                        amount: values.amount 
                    }
                }
            );
        }
    };

    const walletOptions = useMemo(() => {
        if (wallets) {
            return wallets.map(w => (
                {
                    label: (
                        <div className={styles.walletOptionContainer}>
                            {getChainIcon(w.chain.name)}
                            <div>
                                <Text className={styles.walletOptionName}>{w.name}</Text>
                                <Text className={styles.walletOptionAddr}>{w.address.slice(0, 10) + " ...... " + w.address.slice(-10)}</Text>
                            </div>
                        </div>), 
                    value: w.wallet_id
                }));
        }
        return [];
    }, [wallets]);

    const assetOptions = useMemo(() => {
        if (mockData && mockData?.assets) {
            return mockData.assets.map(a => (
                {
                    label: (
                        <div className={styles.assetOptionContainer}>
                            <div>
                                <Text className={styles.assetOptionTicker}>{a.ticker}</Text>
                                <Text className={styles.assetOptionNet}>{a.network}</Text>
                            </div>
                            <div>
                                <Text className={styles.assetOptionValue}>{a.value}</Text>
                                <Text className={styles.assetOptionBalance}>{a.balance?.amount} {a.balance?.currency}</Text>
                            </div>
                        </div>),
                    value: a.asset_id
                })
            );
        }
        return [];
    }, [mockData]);

    const selectedAsset = methods.watch("asset");
    const maxAmount = useMemo(() => {
        if (selectedAsset && mockData && mockData?.assets) {
            const asset = mockData.assets.find(a => a.asset_id === selectedAsset);
            return asset?.value;
        }
    }, [selectedAsset, mockData]);

    const handleSelectAsset = (assetId: string) => {
        if (wallet && wallet?.assets && wallet.assets.length > 0) {
            setNetwork(wallet.assets.find(x => x.asset_id === assetId)?.network);
        }
        methods.resetField("amount");
    }

    return (
        <Modal className={styles.card} parentRoute="/wallets">            
            <FormProvider {...methods}>
                <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)} autoComplete="off">
                <Title underlined>Asset Transfer</Title>
                    <div className={styles.formContent}>
                        <SelectField className={styles.selectInput} name="toWallet" options={walletOptions} placeholder="Destination"/>
                        <SelectField onSelect={handleSelectAsset} className={styles.selectInput} name="asset" options={assetOptions} placeholder="Asset"/>
                        <div className={styles.priorityContainer}>
                            <Text className={styles.priorityLabel}>Priority</Text>
                            <RadioGroup
                                className={styles.radioInputContainer} 
                                name='priority' 
                                options={[{value: 'Low', label: 'Low'}, {value: 'Medium', label: 'Medium'}, {value: 'High', label: 'High'}]}
                            />
                        </div>
                        
                        <NumberField disabled={!selectedAsset} variant='underlined' className={styles.textInput} placeholder="Amount" name="amount" min={1} max={maxAmount}/>
                        <Button
                            className={styles.submitBtn}
                            type="submit"
                            variant="contained"
                            >
                            Request Transfer
                        </Button>
                    </div>
                </form>
            </FormProvider>
        </Modal>
    );
};

export default AssetTransfer;