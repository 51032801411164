'use client';

import React, { ReactNode, useRef } from 'react';
import classNames from 'classnames';
import { Controller, UseControllerProps, useFormContext } from 'react-hook-form';

import { Input, InputVariants } from '../Input';
import { InputMessage } from '../InputMessage';
import styles from './TextField.module.css';
import { Text } from '@components/common/text';

export interface TextFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  endAdornment?: ReactNode;
  startAdornment?: ReactNode;
  variant?: keyof typeof InputVariants;
  className?: string;
  description?: string;
  errorMessage?: string;
  showLabel?: boolean;
}

export const InnerTextField = React.forwardRef<HTMLInputElement, TextFieldProps>(
  ({ startAdornment, endAdornment, className, variant, ...rest }, forwardedRef) => {
    const internalRef = useRef<HTMLInputElement | null>(null);
    const ref = forwardedRef || internalRef;

    return (
      <div className={classNames(styles.inputContainer, className, {[styles.disabled]: rest.disabled})}>
        <div className={styles.inputField}>
          {startAdornment && <div className={styles.iconContainerStart}>{startAdornment}</div>}
          {rest.showLabel && <Text className={classNames(styles.label, { [styles[variant!]]: variant })}>{rest.placeholder}</Text>}
          <Input
            ref={ref}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            variant={variant}
            className={classNames(className, {[styles.inputError]: rest.errorMessage})}
            {...rest}
          />
          {endAdornment && <div className={styles.iconContainerEnd}>{endAdornment}</div>}
        </div>
        <InputMessage message={rest.description} />
        <InputMessage variant="error" message={rest.errorMessage} />
      </div>
    );
  }
);

export const TextField = ({
  name,
  ...otherProps
}: UseControllerProps & TextFieldProps) => {
  const context = useFormContext();
  if (context === undefined || context === null) {
    throw new Error('TextField must be used within a FormProvider from react-hook-form');
  }

  const { control } = context;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <InnerTextField
            {...otherProps}
            {...field}
            errorMessage={fieldState.error ? otherProps.errorMessage || fieldState.error.message : undefined}
            showLabel={field.value}
          />
        );
      }}
    />
  );
};

TextField.displayName = 'TextField';
