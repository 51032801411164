import { PropsWithChildren } from 'react';

import styles from './BoldText.module.css';
import classNames from 'classnames';

export const BoldText = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return (
    <div className={classNames(styles.text, className)}>
      {children}
    </div>
  );
};