import { AggregateWalletSchema, ChainSchema, useGetWallets, WalletSchema } from "@api/__generated__/mainservice";
import { Loader, List, ListColumnType, HamburgerMenu, ListActionType, CopyText, NavigationButton } from "@components/common";
import { useMemo, useState } from "react";
import { getChainIcon } from "@utils/transformers";
import styles from "./Wallets.module.css";
import WalletHeader from "./WalletHeader";
import WalletData from "./ChainData/WalletData";
import { CheckIcon, ErrorIcon, TransferIcon } from "@components/icons";
import { useLocation, useNavigate } from "react-router";
import { getCustodianLogoSrc, isWalletNative } from "@utils/constants/custodian";
import LinkedWalletData from "./ChainData/LinkedWalletData";

export interface Chain extends ChainSchema {
    id: string;
    balance?: number;
}

export interface Wallet extends AggregateWalletSchema {
    id: string;
    custodianId: string;
}

const Wallets = () => {

    const navigate = useNavigate();
    const location = useLocation();

//    const { data: chains, isLoading } = useGetChains();
    const { data: walletData, isLoading } = useGetWallets();
    const [filteredWallets, setFilteredWallets] = useState<Wallet[]>([]);

    const wallets: Wallet[] = useMemo(() => {
        const result = walletData?.map(x => ({ ...x, id: x.wallet_id ?? "", custodianId: x.custodian?.id ?? "cd-000"})) ?? [];
        setFilteredWallets(result);
        return result;
    }, [walletData]);

    const columns: ListColumnType<Wallet>[] = useMemo(
        () => [
            {
                field: 'custodian',
                headerName: '',
                width: '60px',
                renderCell: (params) => (
                    <div className={styles.custodianLogoContainer}>
                        <img className={styles.custodianLogo} src={getCustodianLogoSrc(params.data?.custodian?.id)}/> 
                    </div>
                ),
            },
            {
                field: 'chain',
                headerName: '',
                width: '120px',
                renderCell: (params) => (
                    <div className={styles.chain}>
                        <div className={styles.iconSmall}>{getChainIcon(params.data?.chain.name ?? "")}</div>{params.data?.chain.name}
                    </div>
                ),
            },
            {
                field: 'name',
                headerName: 'Name',
                width: '50%',
            },
            {
                field: 'address',
                headerName: 'Address',
                width: '50%',
                renderCell: (params) => 
                    <CopyText 
                        className={styles.walletAddress}
                        copyValue={params.data?.address}
                        confirmationMessage="Wallet address copied to clipboard!"
                    >
                        {params.data?.address.slice(0, 10) + " ...... " + params.data?.address.slice(-10)}
                    </CopyText>
            },
        ], []
    );

    const actions: ListActionType<Wallet> = useMemo(
        () => 
            ({
                width: '40px',
                textAlign: 'center',
                renderActionCell: (params) => (<>
                    {isWalletNative(params.data?.id) ?
                        <HamburgerMenu
                            id={params.data?.id}
                            options={
                                [
                                    {
                                        label: params.data?.state === "active" ? "Deactivate" : "Activate",
                                        onClick: () => {
                                            navigate(`${params.data?.wallet_id}/${params.data?.state === "active" ? "deactivate" : "activate"}`, 
                                                { state: { backgroundLocation: location } }
                                            )
                                        }
                                    },
                                ]
                            }
                        /> : 
                        <NavigationButton className={styles.transferBtn} variant="text" keepBackground pathTo={`${params.data?.id}/transfer`}>
                            <TransferIcon/>
                        </NavigationButton>
                    }
                </>)
            })
        , [navigate]
    );

    return (
        <>
            {isLoading ? (
                <Loader />
                ) : (
                <List
                    className={styles.list}
                    variant='outlined-free'
                    rowHeight={100}
                    header={(
                        <WalletHeader allWallets={wallets} filterWallets={setFilteredWallets}/>
                    )}
                    columns={columns}
                    data={filteredWallets}
                    content={{renderContent: (params) => (
                        <>
                            {isWalletNative(params.data?.id) ? 
                                <WalletData wallet={params.data} nestedType='primary'/> :
                                <LinkedWalletData walletId={params.data?.id} nestedType='primary'/>
                            }
                        </>
                    )}}
                    actions={actions}
                />
            )}
        </>
    ); 
};

export default Wallets;