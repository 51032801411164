import { getGetGroupsQueryKey, GroupSimpleSchema, useEditGroup, useGetGroups, useGetUsers } from "@api/__generated__/mainservice";
import { ChipArray, HamburgerMenu, List, ListActionType, ListColumnType } from "@components/common";
import { useMemo, useState } from "react";
import styles from './Groups.module.css';
import { AddUserIcon } from "@components/icons";
import GroupHeader from "./GroupHeader";
import { getUserIcon } from "@utils/transformers";
import { useLocation, useNavigate } from "react-router";
import { useQueryClient } from "@tanstack/react-query";

interface Group extends GroupSimpleSchema {
    id: string;
}

const Groups = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    
    const { data, isLoading } = useGetGroups();
    const { data: users } = useGetUsers();
    const { mutate: editGroup } = useEditGroup({ mutation: {
        onSuccess: async () => {
            await queryClient.invalidateQueries({ queryKey: getGetGroupsQueryKey() });
        },
    } });
    
    const [query, setQuery] = useState<string>("");

    const removeUser = (groupId: string, userId: string) => {
        editGroup({ groupId, data: { remove: [userId] } });
    }

    const rows: Group[] = useMemo(() => {
        if (!data) return [];
        return data.map(row =>
            ({
                id: row.group_id,
                ...row
            }))
    }, [data]);

    const filteredRows = useMemo(() => {
        if (rows.length > 0 && query.trim() !== "") {
            return rows.filter(row => row.name.toLowerCase().includes(query.toLowerCase()));
        }
        return rows;
    }, [query, rows]);

    const columns: ListColumnType<Group>[] = useMemo(() => [
        {
            field: 'name',
            headerName: 'Name',
            width: '20%',
        },
        {
            field: 'members',
            headerName: '',
            width: '80%',
            renderCell: (params) => (
                <ChipArray 
                    chips={(users ?? [])
                        .filter(x => params.data?.members.includes(x.user_id))
                        .map(x => 
                            ({
                                startIcon: getUserIcon(x.type),
                                label: x.name, 
                                value: x.user_id
                            })
                        )}
                    noDataMessage="No users"
                    addIcon={(<AddUserIcon/>)}
                    onAdd={() => navigate(`${params.data?.group_id}/adduser`, { state: { backgroundLocation: location } })}
                    onRemove={(value) => removeUser(params.data?.group_id ?? "", value)}
                />
            )
        }
    ], [users]);

    const actions: ListActionType<Group> = useMemo(
        () => 
            ({
                width: '40px',
                textAlign: 'center',
                renderActionCell: (params) => (
                    <HamburgerMenu 
                        id={params.data?.id}
                        options={
                            [
                                {
                                    label: 'Edit',
                                    onClick: () => {navigate(`${params.data?.group_id}/edit`, { state: { backgroundLocation: location } })}
                                },
                                {
                                    label: 'Delete',
                                    onClick: () => {navigate(`${params.data?.group_id}/delete`, { state: { backgroundLocation: location } })},
                                    className: styles['error']
                                }
                            ]
                        }
                    />
                )
            })
        , [navigate]
    );

    return (
        <List
            className={styles.list}
            variant='outlined-free'
            header={(
                <GroupHeader onSearch={(search) => setQuery(search)}/>
            )}
            isLoading={isLoading}
            rowHeight={100}
            columns={columns}
            data={filteredRows}
            actions={actions}
        />
    ); 
};

export default Groups;